<template>
  <div class="checkout-layout">
    <CheckoutHeader />
    <Nuxt class="checkout-layout__content" />
    <Notification />
    <CheckoutFooter />
  </div>
</template>

<script>
import {
  defineComponent,
  useFetch,
  provide,
  useContext,
  useMeta,
  useRoute,
} from '@nuxtjs/composition-api';
import { useTranslation, useCart } from '~/composables/';
import {
  CheckoutFooter,
  CheckoutHeader,
} from '~/components/Checkout/CommonElements/';
import Notification from '~/components/Notification.vue';

export default defineComponent({
  name: 'CheckoutLayout',
  components: {
    CheckoutFooter,
    CheckoutHeader,
    Notification,
  },
  head: {},
  setup() {
    const route = useRoute();
    const { cartId } = useCart();
    provide('cartId', cartId.value);

    const { loadTranslations } = useTranslation('translations');
    useFetch(async () => {
      await loadTranslations();
    });

    useMeta({
      htmlAttrs: {
        lang: route?.value?.path?.split('/')?.[1] === 'it' ? 'it' : 'en',
      },
    });

    return {
      ...useTranslation('translations'),
    };
  },
});
</script>

<style lang="scss">
@import '../assets/style/variables.scss';
@import '@/static/css/reset.css';
.checkout-layout {
  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: 157px;
  @include from-desktop-min {
    margin-top: 85px;
  }
  &__content {
    flex: 1 0 auto;
  }
}
</style>
